import React from "react";
import { Container } from "./styles";
import { SectionPost } from "../../../types/Post";

interface PostsSectionProps {
  postsSection: SectionPost[];
}

export const PostsSection: React.FC<PostsSectionProps> = ({
  postsSection
}) => (
  <Container>
    {postsSection.map((post: SectionPost, index) => (
      <div>
        <h3>{post.title}</h3>
        <p>{post.body}</p>
        <ul>
          {post.bulletList && post.bulletList.map(bulletItem => (
            <li><strong>{bulletItem.highlight}</strong> {bulletItem.body}</li>
          ))}
        </ul>
        <ol>
        {post.numberedList && post.numberedList.map(bulletItem => (
          <li><strong>{bulletItem.highlight}</strong> <br /> {bulletItem.body}</li>
        ))}
        </ol>
        {index < postsSection.length-1 && <hr />}
      </div>
    ))}
  </Container>
);
