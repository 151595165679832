import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Home from './pages/Home';
import ContactPage from './pages/ContactPage';
import './App.css';
import { PrivacyPolicyPage } from './pages/PrivacyPolicyPage';
import NotFoundPage from './pages/NotFoundPage';

// Helper function to initialize Google Analytics
const initializeAnalytics = () => {
  ReactGA.initialize('G-SDPMFRPJ3K');
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
  });
};

const checkAnalyticsConsent = () => {
  if (window.cookieyes && window.cookieyes._ckyConsentStore) {
    const consentStore = window.cookieyes._ckyConsentStore;
    
    // Convert Map to an object for easier handling
    const consentObj: any = Object.fromEntries(consentStore);

    // Check if analytics consent is granted
    return consentObj.analytics === 'yes';
  }
  return false;
};

const checkConsentAndInitialize = () => {
  if (process.env.NODE_ENV === 'production') {
    if (checkAnalyticsConsent()) {
      initializeAnalytics(); // Call your Google Analytics initialization here
    }
  }
};

function App() {
  useEffect(() => {
    // Check consent on component mount
    checkConsentAndInitialize();

    // Listen for consent changes and recheck
    window.addEventListener('CookieConsentChange', checkConsentAndInitialize);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('CookieConsentChange', checkConsentAndInitialize);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <BrowserRouter>
      <div className="App">
        <header className="App-header">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </header>
      </div>
    </BrowserRouter>
  );
}

export default App;
