import React from "react";
import { Container } from "./styles";

const NotFound: React.FC = () => (
  <Container>
    <h1>404 Page Not Found</h1>
      <p>Oops! The page you are looking for does not exist.</p>
      <p>You might want to:</p>
      <ul>
        <li>Check the URL for errors</li>
        <li>Return to the <a href="/">homepage</a></li>
      </ul>
      <div style={{ marginTop: '20px' }}>
        <a href="/">← Back to TextToCase</a>
    </div>
  </Container>
)

export default NotFound;
