import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding-top: 2rem;

  hr {
    border: none;
    height: 1px;
    background-color: #e7ddef;
  }
`;