import React from 'react'
import { FooterBase, FooterCenter, FooterLeft, FooterRight } from './styles'
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <FooterBase>
      <FooterLeft></FooterLeft>
      <FooterCenter>© 2024 TextToCase</FooterCenter>
      <FooterRight>
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/contact">Contact</Link>
      </FooterRight>
    </FooterBase>
  );
}

export default Footer