import React, { RefObject } from 'react';
import { Link } from 'react-router-dom';
import { Container, Links, LinkToTutorial, Logo } from './styles';

interface MenuProps {
  tutorialRef?: RefObject<HTMLDivElement>;
}

const Menu : React.FC<MenuProps> = ({ tutorialRef }) => {
  const handleToTutorial = () => {
    // Scroll to the tutorial section
    tutorialRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }

  return (
      <nav className="Menu">
        <Container>
          <Logo>
            <Link to="/">
              <img className="Logo"
                src={"/assets/images/logo.png"}
                alt="Convert Case App"
              />
            </Link>
          </Logo>
          {/* <Links>
            <LinkToTutorial onClick={handleToTutorial}>HOW IT WORKS</LinkToTutorial>
          </Links> */}
        </Container>
      </nav>
  )
}

export default Menu