import React from "react";
import { Container } from "./styles";

export const Contact: React.FC = () => {
  return(
    <Container>
      <h1>Contact</h1>
      <p>
        If you find an error or have a suggestion, feel free to reach out via the email below. 
        Your input is appreciated!
      </p>
      <p>
        <a href="mailto:contact@texttocase.com">
          contact@texttocase.com
        </a>
      </p>
    </Container>
  );
};