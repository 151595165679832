import styled from 'styled-components'

export const FooterBase = styled.footer`
  display: flex;
  justify-content: space-between;
  border-top: 2px solid #e7ddef;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 32px;
  padding-bottom: 32px;
  color: var(--greyDark);
  text-align: center;
  @media (max-width: 800px) {
    margin-bottom: 50px;
  }

  a {
    color: var(--purpleDark);
    text-decoration: none;
    cursor: pointer;
  }

  a:hover {
    color: var(--black);
    border-bottom: 1px solid var(--black);
  }
`;

export const FooterLeft = styled.div`
  width: 33%;

  @media (max-width: 800px) {
    width: 0;
  }
`;
export const FooterCenter = styled.div`
  width: 33%;

  @media (max-width: 800px) {
    text-align: left;
    width: 50%;
  }
`;
export const FooterRight = styled.div`
  width: 33%;
  text-align: right;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 800px) {
    width: 50%;
    flex-direction: column;
  }

  a {
    margin-left: 1rem;
  }
`;