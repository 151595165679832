export const HowItWorks = {
  Title: "Easily Change Your Text Case",
  DescriptionOne:
    "TextToCase is a fast and easy solution for converting text into any format you need. From UPPER CASE to lower case, Title Case, and more, this tool makes it simple to adjust text with a single click.",
  DescriptionTwo:
    "Just paste your text into the box, choose the desired case, and instantly transform it.",
  DescriptionThree:
    "Whether fixing accidental caps lock errors, formatting headlines, or customizing text for creative purposes, you can easily copy the results to your device.",
  ExploreMoreOptions: "Explore all the options below.",
  CopyToClipboard:
    "Once you've converted your text, easily copy the result by clicking the copy icon. You can then paste it directly into your document!",
};
