export const postsSection = [
  {
    id: 1,
    title: "Use Cases for Text Formatting",
    body: "Good formatting is about more than just aesthetics—it directly impacts how your audience perceives and understands your content. Properly formatted text can enhance readability, guide the reader through the material, and ensure that your message is clear and effective. Here are some key ways text formatting improves readability:",
    bulletList: [
      {
        highlight: "Writers and Editors:",
        body: "Maintain consistency in your writing style.",
      },
      {
        highlight: "Students:",
        body: "Ensure your essays and papers are properly formatted.",
      },
      {
        highlight: "Programmers:",
        body: "Convert code elements like function names or variable names to match your coding style.",
      },
      {
        highlight: "Social Media Managers:",
        body: "Ensure your posts are visually appealing and formatted for quick readability.",
      },
    ],
    numberedList: [],
  },
  {
    id: 2,
    title: "How Proper Text Formatting Enhances Readability",
    body: "",
    bulletList: [],
    numberedList: [
      {
        highlight: "Consistency",
        body: "Inconsistent capitalization, such as randomly capitalizing words or switching between upper and lower case, can confuse your reader and make your writing look unprofessional. A simple rule, like consistently using sentence case or title case, can make a huge difference in clarity.",
      },
      {
        highlight: "Focus",
        body: "Capitalizing the first word of each sentence helps the reader follow the flow of your argument or narrative. It shows where new thoughts begin and helps to emphasize key points.",
      },
      {
        highlight: "Visual Breaks",
        body: "When used appropriately, formatting techniques like bolding, italics, and capitalized words can create natural pauses in the text, making it easier to scan and digest.",
      },
      {
        highlight: "Improved Accessibility",
        body: "For those with visual impairments or reading difficulties, proper capitalization can make a huge difference. Sentence case and title case help ensure that text-to-speech programs correctly interpret and relay information.",
      },
    ],
  },
  {
    id: 3,
    title: "Best Practices for Formatting",
    body: "",
    bulletList: [
      {
        highlight: "Sentence Case",
        body: "Sentence case can be used for longer paragraphs, making your content more approachable and readable",
      },
      {
        highlight: "Title Case",
        body: "Can be used for headings or subheadings, ensuring your titles stand out and catch attention.",
      },
      {
        highlight: "Upper Case",
        body: "Reserve this for abbreviations or instances where emphasis is crucial, but avoid using it for entire paragraphs as it can be hard to read.",
      },
      {
        highlight: "Capitalized Case",
        body: "This can be helpful for section headers, presentations, or other short-form content where each word needs to stand out.",
      },
    ],
    numberedList: [],
  },
];
