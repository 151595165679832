export const countCharacters = (input: string) => {
  // Character count including spaces
  const characterCount = input.length;

  // Words count (split spaces and filter out empty strings)
  const wordCount = input
    .trim()
    .split(/\s+/)
    .filter((word) => word.length > 0).length;

  // Line count (split by newlines, includes empty lines)
  const lineCount = input.split(/\r\n|\r|\n/).length;

  return {
    characterCount,
    wordCount,
    lineCount,
  };
};
