import React, { RefObject } from 'react';
import { HowItWorks } from '../../../constants/strings';

import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap; /* This will allow items to wrap into multiple rows */
  justify-content: flex-start; /* Align items to the left */
  gap: 1rem; /* Adjust the gap as necessary */
  padding: 1% 0 1% 0;
  width: 100%;
  margin-bottom: 2rem;

  @media (min-width: 2560px) {
    gap: 8rem; /* QHD+ */
  }

   /* For screens between 800px and 1000px (tablet landscape) */
   @media (max-width: 1280px) and (min-width: 1151px) {
    gap: 1rem; /* Adjust the gap for better spacing on tablets */
  }

  /* For screens between 800px and 1000px (tablet landscape) */
  @media (max-width: 1280px) and (min-width: 1151px) {
    gap: 1rem; /* Adjust the gap for better spacing on tablets */
  }

  /* For screens between 800px and 1000px (tablet landscape) */
  @media (max-width: 1280px) and (min-width: 1151px) {
    gap: 1rem; /* Adjust the gap for better spacing on tablets */
  }

  /* For screens between 800px and 1000px (tablet landscape) */
  @media (max-width: 1150px) and (min-width: 801px) {
    gap: .8rem; /* Adjust the gap for better spacing on tablets */
  }


  /* For screens smaller than 800px (mobile or tablet portrait) */
  @media (max-width: 800px) {
    width: 96%;
    flex-direction: column; /* Stack items in a column */
    gap: 1rem;
  }

  button {
    border: none;
    background: transparent;
    outline:none;
  }

  svg {
    width: 1.2rem;
    height: 1.2rem;
    fill: var(--black);
  }
`;

interface StyledColProps {
  size: number;
}

export const Col = styled.div<StyledColProps>`
  flex: ${(props) => props.size};
  padding: 0.5%;
`;

export const Card = styled.div`
  padding: 0 2rem 0 2rem;
  background-color: var(--white);
  border: 1px solid var(--black);
  border-radius: 50px;
  width: 25%;
  box-shadow: 0 0 1px 1px var(--greyDark);

  /* For screens between 800px and 1000px (tablet landscape) */
  @media (max-width: 1024px) and (min-width: 801px) {
    width: 23%;
  }

  @media (max-width: 800px) {
    width: auto;
    margin-bottom: 2rem;
  }

  h3 {
    text-align: center;
  }
`;

export const CardSecondary = styled.div`
  padding: 0 2rem 0 2rem;
  background-color: var(--purple);
  border: 1px solid var(--black);
  border-radius: 50px;
  width: 25%;
  box-shadow: 0 0 1px 1px var(--greyDark);

  /* For screens between 800px and 1000px (tablet landscape) */
  @media (max-width: 1024px) and (min-width: 801px) {
    width: 25%;
  }

  @media (max-width: 800px) {
    width: auto;
    margin-bottom: 2rem;
  }

  h3 {
    text-align: center;
  }
`;

export const CardIcon = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  margin-top: -30px;
  font-size: 28px;

  span {
    background-color: var(--black);
    color: var(--white);
    border-radius: 50%;
    padding: .5rem 1rem;
    min-width: 34px;
  }
`;

interface TutorialProps {
  tutorialRef?: RefObject<HTMLDivElement>;
}

export const Tutorial: React.FC<TutorialProps> = ({ tutorialRef }) => {
  return (
    <>
      <Row ref={tutorialRef}>
        <Col size={1}>
        <h1>{ HowItWorks.Title }</h1>
          <p>
            { HowItWorks.DescriptionOne }
            <br />
            { HowItWorks.DescriptionTwo }
            <br />
            { HowItWorks.DescriptionThree }
          </p>
          <p>
            {HowItWorks.CopyToClipboard}
            <button title="Copy to clipboard">
              <svg viewBox="0 0 16 16" role="img" data-name="tail">
                <path d="M10 4v-4h-7l-3 3v9h6v4h10v-12h-6zM3 1.414v1.586h-1.586l1.586-1.586zM1 11v-7h3v-3h5v3l-3 3v4h-5zM9 5.414v1.586h-1.586l1.586-1.586zM15 15h-8v-7h3v-3h5v10z"></path>
              </svg>
            </button>
            <br />
            { HowItWorks.ExploreMoreOptions }
          </p>
        </Col>
      </Row>
      <Row>
        <Card>
          <CardIcon><span>#1</span></CardIcon>
          <h3>Upper Case</h3>
          <p>
            The upper case converter turns all your text into big, bold capitals.  
            Just paste your text into the box above, hit the ‘UPPER CASE’ button, 
            and voilà – every letter is now in uppercase glory!
          </p>
          <p>THIS IS AN EXAMPLE OF UPPER CASE</p>
        </Card>
        <CardSecondary>
          <CardIcon><span>#2</span></CardIcon>
          <h3>Title Case</h3>
          <p>Title case converter makes sure the right words are capitalized, like 'Title,' 
          while leaving smaller words like 'of' in lowercase. 
          Just paste your text, hit the ‘Title Case’ button, and get a perfectly polished title 
          in no time!</p>
          <p>
            This Is an Example of Title Case
          </p>
        </CardSecondary>
        <Card>
          <CardIcon><span>#3</span></CardIcon>
          <h3>Lower Case</h3>
          <p>
            The lower case converter turns all your letters into 
            lowercase in a snap. Just copy your text, paste it into the box above, 
            and hit the ‘lower case’ button. Watch as your text transforms into a smooth sea of lowercase letters!</p>
          <p>
            this is an example of lower case
          </p>
        </Card>
      </Row>
      <Row>
        <Card>
        <CardIcon><span>#4</span></CardIcon>
        <h3>Sentence Case</h3>
          <p>
            With sentence case converter, you can easily paste 
            any text and watch it transform into perfectly structured sentences. 
            It capitalizes the very first letter of each sentence, turns the rest 
            into lowercase, and switches i’s to I’s. 
            Plus, every letter after a full stop gets an uppercase boost. 
            <br />Just a heads-up: it won’t capitalize names or places.
          </p>
          <p>
            This is an example of sentence case
          </p>
        </Card>
        <CardSecondary>
          <CardIcon><span>#5</span></CardIcon>
          <h3>Capitalized Case</h3>
          <p>
          Capitalized case converter turns the first letter of every word into uppercase while 
          keeping the rest in lowercase. Just copy your text, paste it into the box above,
          and hit the ‘Capitalized Case’ button.
          </p>
          <p>
            This Is An Example Of Capitalized Case
          </p>
        </CardSecondary>
      </Row>
    </>
  )
}

export default Tutorial