import styled from 'styled-components'

interface StyledButton {
  type?: 'primary' | 'secondary';
}

const Button = styled.button<StyledButton>`
  width: 100%;
  border: 2px solid var(--black);
  color: var(--black);
  background-color: var(--white);
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  padding: .6rem;
  font-style: normal;
  font-size: 16px;
  outline: none;
  border-radius: 10px;
  text-decoration: none;
  display: inline-block;
  transition: opacity .3s;
  border-style: solid;
  font-weight: 900;
  letter-spacing: .8px;
  //margin-right: 1%;
  border-right: 3px solid var(--black);
  border-bottom: 3px solid var(--black);
  
  ${props => props.type === 'primary' && `
    background-color: var(--purple);
  `}

  &:hover {
    color: var(--white);
    background-color: var(--black);
  }

  &:focus {
    color: var(--white);
    background-color: var(--black);
  }
`;

export default Button