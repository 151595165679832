import React from "react";
import { Content, PageSection } from "../../../types/Page";
import { Container } from "./styles";

interface PrivacyPolicyProps {
  privacyPolicy: PageSection[];
}

export const PrivacyPolicy: React.FC<PrivacyPolicyProps> = ({
  privacyPolicy
}) => {

  const mountBody = (body: Content[]) => {
    return body.map((content: Content, index) => {
      switch (content.type) {
        case 'p':
          return <div key={`${content.type}-${index}`}>{content.body}<br /></div>;
        case 'bl':
          return (
            <ul key={`${content.type}-${index}`}>
              {content.body.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          );
        default:
          return null;
      }
    }
  )};

  return(
    <Container>
      <h1>Privacy Policy</h1>
      {privacyPolicy.map((item: PageSection, index) => (
        <div key={`${index}-${item.title}`}>
          <h2>{item.title}</h2>
          <>{mountBody(item.body)}</>
        </div>
      ))}
    </Container>
  );
};
