import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Main = styled.main`
  width: auto;
  background-color: var(--grayLight);
  color: var(--black);
  flex: 1;
  padding-top: 2rem;
  padding-left: 12rem;
  padding-right: 12rem;

  /* For screens between 800px and 1000px (tablet landscape) */
  @media (max-width: 1366px) and (min-width: 801px) {
    padding-left: 4%;
    padding-right: 7%;
  }

  @media (max-width: 800px) {
    padding-left: 3%;
    padding-right: 3%;
  }
`;