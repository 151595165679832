import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const FormFieldWrapper = styled.div`
  position: relative;

  textarea {
    min-height: 210px;
    outline: none;
    border: none;
    background: none;
    width: 98.8%;
    font-size: 16px;
    
    /* @media (max-width: 800px) {
      width: 96%;
    } */

    &::placeholder {
      color: var(--yellowDark);
    }
  }

  input[type="color"] {
    padding-left: 56px;
    background: #F7F7F7;
    border: 2px solid #E5E5E5;
    border-radius: 16px;
  }

  button {
    border: none;
    background: transparent;
    position:absolute;
    bottom:7px;
    right:12px;
    outline:none;
  }

  svg {
    width: 2rem;
    height: 2rem;
    fill: var(--black);

    &:hover {
      fill: var(--greyDark);
      cursor: pointer;
    }
  }
`;

const Input = styled.input`
  display: block;
  width: 100%;
  height: 57px;
  font-size: 16px;
  position: relative;
  padding: 16px 16px;
  margin-bottom: 45px;
  resize: none;
  transition: border-color .3s;
  border-color: transparent;
  
  /* TODO: review file to add code here */
`;

interface FormFieldProps {
  type?: string;
  name?: string;
  value?: string; 
  suggestions?: any;
  placeholder?: string;
  enableCopy?: boolean;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  handleImgClick?: () => void;
}

const FormField = ({
  type, name, value, onChange, suggestions, placeholder, enableCopy, handleImgClick
} : FormFieldProps ) => {
  const fieldId = `id_${name}`
  const isTypeTextarea = type === 'textarea'
  const tag = isTypeTextarea ? 'textarea' : 'input'
  const hasSuggestions = Boolean(suggestions.length)

  return (
    <FormFieldWrapper>
      <Input
        as={tag}
        id={fieldId}
        type={type}
        value={value}
        name={name}
        // hasValue={hasValue}
        onChange={onChange}
        placeholder={placeholder}
        autoComplete={hasSuggestions ? 'off' : 'on'}
        list={hasSuggestions ? `suggestionFor_${fieldId}` : undefined}
      />
      {
        hasSuggestions && (
          <datalist id={`suggestionFor_${fieldId}`}>
            {
            suggestions.map((suggestion: any) => (
              <option value={suggestion} key={`suggestionFor_${fieldId}_option${suggestion}`}>
                {suggestion}
              </option>
            ))
          }
          </datalist>
        )
      }
      {enableCopy &&
        <button title="Copy to clipboard">
          <svg viewBox="0 0 16 16" onClick={handleImgClick} role="img" data-name="tail">
            <path d="M10 4v-4h-7l-3 3v9h6v4h10v-12h-6zM3 1.414v1.586h-1.586l1.586-1.586zM1 11v-7h3v-3h5v3l-3 3v4h-5zM9 5.414v1.586h-1.586l1.586-1.586zM15 15h-8v-7h3v-3h5v10z"></path>
          </svg>
        </button>
      }
    </FormFieldWrapper>
  )
}

FormField.defaultProps = {
  type: 'text',
  value: '',
  onChange: () => {},
  suggestions: [],
}

FormField.propTypes = {
  // label: PropTypes.string.isRequired,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.string),
}

export default FormField
