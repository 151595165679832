import React from "react";
import PageContainer from "../components/ui/PageContainer/PageContainer";
import { Contact } from "../components/ui/Contact/Contact";

export const ContactPage: React.FC = () => {
  return (
    <PageContainer>
      <Contact />
    </PageContainer>
  );
}

export default ContactPage;