import styled from "styled-components";

export const Container = styled.div`
  //display: flex;
  width: 100%;
  /* min-height: 490px; */
  padding-bottom: 2rem;

  @media (max-width: 800px) {
    display: flex;
    width: 100%;
  }
`

export const BoxForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: auto;
  background-color: var(--yellow);
  border: 1px solid var(--black);
  border-radius: 3rem;
  box-shadow: 0 0 1px 1px var(--greyDark);
  min-height: 250px;
  padding: 1rem;

  @media (max-width: 800px) {
    width: auto;
    margin-bottom: .5rem;
  }
`