import { PageSection } from "../types/Page";

export const privacyPolicy: PageSection[] = [
  {
    title: "Information We Collect",
    body: [
      {
        type: "p",
        body: "TextToCase is a simple tool for converting text to various formats (uppercase, lowercase, sentence case, title case, and capitalized case). We do not collect or store any personal information, text input, or any data that you process through the tool. All text transformations are performed locally in your browser and are not sent to our servers.",
      },
      {
        type: "p",
        body: "However, we may collect certain types of non-personal information when you use our site, including:",
      },
      {
        type: "bl",
        body: [
          "IP address",
          "Browser type and version",
          "Device type (desktop, mobile)",
          "Referring website",
        ],
      },
      {
        type: "p",
        body: "This data is used to improve our services and understand how users interact with our app.",
      },
    ],
  },
  {
    title: "Cookies and Tracking Technologies",
    body: [
      {
        type: "p",
        body: "We use cookies to enhance your experience on our site and to serve relevant ads. Cookies are small data files that are placed on your device to help us analyze site traffic and usage. These cookies may also be used by third-party advertising services like Google Ads.",
      },
      {
        type: "p",
        body: "Google Ads may collect cookies and use other tracking technologies to show you personalized ads based on your interests. You can manage your cookie preferences by adjusting your browser settings or opting out through Google’s Ad Settings. ",
      },
      {
        type: "p",
        body: "We advise you to consult the respective Privacy Policies of these third-party ad servers for more detailed information.",
      },
    ],
  },
  {
    title: "Third-Party Links",
    body: [
      {
        type: "p",
        body: "Our website may contain links to third-party websites, including advertisements. We are not responsible for the privacy practices or content of these sites. We recommend reviewing the privacy policies of any third-party sites you visit through links on our app.",
      },
    ],
  },
  {
    title: "Changes to This Privacy Policy",
    body: [
      {
        type: "p",
        body: "We may update this privacy policy from time to time. Any changes will be posted on this page with an updated revision date.",
      },
    ],
  },
];
