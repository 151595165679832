import React from "react";
import NotFound from "../components/ui/NotFound/NotFound";
import PageContainer from "../components/ui/PageContainer/PageContainer";

const NotFoundPage: React.FC = () => (
  <PageContainer>
    <NotFound />
  </PageContainer>
)

export default NotFoundPage;